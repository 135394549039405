<template>
  <div class="page page-home page-current">
    <div class="page-content content-area pt-40 pb-0">
      <div class="searchbar-backdrop"></div>
      <div class="container">
        <TopBar :cartTotal="cartTotal" @toggle-cart="handleToggleCart" @toggle-main="handleToggleMain"></TopBar>

        <MainPage v-if="showMain" :prodProList="prodProList" :categoriesList="categoriesList" :promoList="promoList"
                  @toggle-category-products="handleCategory"></MainPage>

        <ProductCategory v-if="showProductCategory" :category="productCategory"
                         @toggle-main="handleToggleMain"></ProductCategory>

        <ShopCart v-if="showCart" :cartItems="cartItems" @toggle-main="handleToggleMain"></ShopCart>

        <CheckoutOrder v-if="showCheckoutOrder" :totalPrice="totalPrice" :showCheckoutOrder="showCheckoutOrder"
                       :hasCookies="hasCookies"
                       @toggle-main="handleToggleMain"
                       @toggle-order-confirm="handleToggleOrderConfirm"></CheckoutOrder>

        <OrderConfirm v-if="showOrderConfirm" :paymentNumber="paymentNumber"></OrderConfirm>
      </div>
      <CreateOrder :totalPrice="totalPrice" v-if="cartItems.length !== 0 && showCreateOrder"
                   @toggle-create-order="handleToggleCreateOrder"></CreateOrder>

      <FooterBar></FooterBar>

      <CookieBar v-if="showCookiesBar" @toggle-cookie="handleCookie"></CookieBar>
    </div>
  </div>
</template>

<script>
import Swiper from 'swiper';
import axios from "axios";
import TopBar from "@/components/TopBar";
import CreateOrder from "@/components/CreateOrder";
import {mapGetters} from "vuex";
import MainPage from "@/components/MainPage";
import ShopCart from "@/components/ShopCart";
import ProductCategory from "@/components/ProductCategory";
import CheckoutOrder from "@/components/CheckoutOrder";
import OrderConfirm from "@/components/OrderConfirm";
import FooterBar from "@/components/FooterBar";
import VueCookies from "vue-cookies";
import {ref, provide} from 'vue';
import CookieBar from "@/components/CookieBar";

const cachedCategories = localStorage.getItem('cachedCategories');
const cachedPriority = localStorage.getItem('cachedPriority');
const cachedPromo = localStorage.getItem('cachedPromotion');

export default {
  name: 'HomeView',
  components: {
    CookieBar,
    FooterBar, OrderConfirm, CheckoutOrder, ProductCategory, ShopCart, MainPage, CreateOrder, TopBar
  },

  setup() {
    const $cookies = ref(VueCookies);
    provide('$cookies', $cookies);
  },

  data() {
    return {
      categoriesList: [],
      prodProList: [],
      promoList: [],
      VUE_API: process.env.VUE_APP_SERVICE_URL,
      showCart: false,
      showMain: true,
      showSearchBar: true,

      showProductCategory: false,
      productCategory: '',

      cartProductList: [],

      showCheckoutOrder: false,
      showCreateOrder: true,
      showOrderConfirm: false,
      showCookiesBar: true,
      hasCookies: false,

      paymentNumber: undefined,
      waitingOrder: localStorage.getItem('waitingOrderNumber'),
    }
  },

  computed: {
    ...mapGetters(['getCartItems', 'getTotalPrice']),
    cartItems() {
      return this.getCartItems
    },
    totalPrice() {
      return this.getTotalPrice
    },
    cartTotal() {
      return this.$store.getters.getCartTotal;
    },
  },

  methods: {
    getTotalText(total) {
      return total > 1 ? `${total} produtos` : `${total} produto`;
    },

    addToCart(id) {
      this.$store.commit('addToCard', this.prodProList.find(i => i.id === id))
    },
    handleToggleCart(cartOpen) {
      this.showCart = cartOpen;
      this.showMain = !cartOpen;
      this.showSearchBar = !cartOpen;
      this.showProductCategory = !cartOpen;
      this.showCheckoutOrder = !cartOpen;
      this.showOrderConfirm = !cartOpen;
      this.showCreateOrder = true;
    },
    handleToggleMain() {
      this.showCart = false;
      this.showMain = true;
      this.showSearchBar = true;
      this.showProductCategory = false;
      this.showCheckoutOrder = false;
      this.showCreateOrder = true;
      this.showOrderConfirm = false;
      localStorage.removeItem('waitingOrderNumber');
    },
    handleCategory(category) {
      this.productCategory = category;
      this.showProductCategory = true;
      this.showMain = false;
      this.showSearchBar = false;
      this.showCreateOrder = true;
      this.showOrderConfirm = false;
    },
    handleToggleCreateOrder() {
      this.showCart = false;
      this.showMain = false;
      this.showSearchBar = false;
      this.showProductCategory = false;
      this.showCheckoutOrder = true;
      this.showCreateOrder = false;
      this.showOrderConfirm = false;
    },
    handleToggleOrderConfirm(data) {
      this.$store.commit('clearCart')
      this.paymentNumber = data
      this.showCart = false;
      this.showMain = false;
      this.showSearchBar = false;
      this.showProductCategory = false;
      this.showCheckoutOrder = false;
      this.showCreateOrder = false;
      this.showOrderConfirm = true;
    },
    handleCookie() {
      if (this.$cookies.get('_gc_cookies') === '1') {
        this.hasCookies = true;
        this.showCookiesBar = false;
      }
    },
  },

  async mounted() {
    if (this.waitingOrder) {
      this.handleToggleOrderConfirm();
    }

    if (cachedPromo) {
      this.promoList = JSON.parse(cachedPromo);
    } else {
      await axios({
        method: 'get',
        url: process.env.VUE_APP_SERVICE_URL + 'promo',
      })
          .then(response => {
            localStorage.setItem('cachedPromotion', JSON.stringify(response.data));
            this.promoList = response.data;
          })
          .catch(error => {
            console.error(error.message);
          });
    }

    if (cachedCategories) {
      this.categoriesList = JSON.parse(cachedCategories);
    } else {
      await axios({
        method: 'post',
        url: process.env.VUE_APP_SERVICE_URL + 'category',
      })
          .then(response => {
            localStorage.setItem('cachedCategories', JSON.stringify(response.data));
            this.categoriesList = response.data;
          })
          .catch(error => {
            console.error(error.message);
          });
    }

    if (cachedPriority) {
      this.prodProList = JSON.parse(cachedPriority);
    } else {
      await axios({
        method: 'get',
        url: process.env.VUE_APP_SERVICE_URL + 'products/priority',
      })
          .then(response => {
            localStorage.setItem('cachedPriority', JSON.stringify(response.data));
            this.prodProList = response.data;
          })
          .catch(error => {
            console.error(error.message);
          });
    }

    new Swiper('.swiper-init', {
      spaceBetween: parseInt(this.$el.dataset.spaceBetween) || 20,
      slidesPerView: this.$el.dataset.slidesPerView || 'auto',
      centeredSlides: this.$el.dataset.centeredSlides === 'true'
    });
  }

}

</script>

<style>

.page-content {
  padding-bottom: 1.5%;
}

</style>
