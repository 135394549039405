<template>
  <!-- Products -->
  <div class="title-bar" style="margin-bottom: 0px"><h2 class="dz-title">Os mais procurados</h2></div>
  <div data-space-between="20" data-slides-per-view="auto" data-centered-slides="false"
       class="swiper swiper-init main-swiper mb-40 overlay-swiper1 swiper-initialized swiper-horizontal swiper-pointer-events swiper-backface-hidden">
    <div class="swiper-wrapper">
      <div class="swiper-slide" v-for="prod in prodProList" :key="prod.id">
        <div class="card-overlay style-1 bg-primary"
             style="height: 15.5em; display: flex; flex-wrap: wrap; align-content: center;"
             @click="addToCart(`${ prod.id }`)">
          <div class="dz-media">
            <img :src="`${ prod.image }`" style="height: 140px" alt=""/>
          </div>
          <div class="dz-info">
            <h5 class="item-title title"><a href="/item-details/">{{ prod.title }}</a></h5>
            <div class="dz-meta" style="width: 180px">
              <ul style="justify-content: space-between">
                <li class="price">{{ prod.price }}<sup>€</sup></li>
                <li class="mt-5"><img style="width: 24px; height: 24px" :src="`${ allergen }`" v-for="allergen in prod.alergenio_image"
                           :key="allergen.id"/></li>
              </ul>
            </div>
            <div class="dz-meta">

            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

  <!-- Categories -->
  <div class="title-bar">
    <h4 class="dz-title">Categorias</h4>
  </div>
  <div data-space-between="20" data-slides-per-view="auto" data-centered-slides="false"
       class="swiper swiper-init mb-40 main-swiper categories-swiper">
    <div class="swiper-wrapper">
      <div class="swiper-slide" v-for="item in categoriesList" :key="item.id">
        <a @click="toggleCategoryId(`${ item.id }`)">
          <div class="categories-bx" style="align-items: center;">
            <div class="icon-bx">
              <a href="/">
                <img :src="`${ item.image }`" alt="" style="fill: #debdbd;">
              </a>
            </div>
            <div class="dz-content">
              <h5 class="title"><a href="/" @click="toggleCategoryId(`${ item.id }`)">{{
                  item.title
                }}</a></h5>
              <span class="menus text-primary">{{ getTotalText(item.total) }}</span>
            </div>
          </div>
        </a>
      </div>
    </div>
  </div>

  <!-- Promoções -->
  <PromoBar v-if="promoList.length > 0" :promoList="promoList"></PromoBar>
</template>

<script>
import axios from "axios";
import Swiper from "swiper";
import PromoBar from "@/components/PromoBar";


export default {
  name: 'MainPage',
  components: {PromoBar},
  props: ['prodProList', 'categoriesList', 'categoryId', 'promoList'],
  emits: ['toggle-category-products'],

  async mounted() {
    this.clearCache()
    new Swiper('.swiper-init', {
      // Swiper configuration options
      spaceBetween: 20,
      slidesPerView: 'auto',
      centeredSlides: false,
    });
  },

  methods: {
    clearCache() {
      setTimeout(() => {
        localStorage.clear();
      }, 100);
    },

    //300000

    addToCart(id) {
      this.$store.commit('addToCard', this.prodProList.find(i => i.id === id))
    },
    getTotalText(total) {
      return total > 1 ? `${total} produtos` : `${total} produto`;
    },
    async toggleCategoryId(id) {
      let categoryProductList = {};
      try {
        const category = await this.loadCategory(id);
        const products = await this.loadProducts(id);
        categoryProductList['category'] = category;
        categoryProductList['products'] = products;
        this.$emit('toggle-category-products', categoryProductList);
      } catch (error) {
        console.error(error.message);
      }
    },

    async loadProducts(id) {
      const regex = /[a-zA-Z0-9]/g;
      let productUrl = process.env.VUE_APP_SERVICE_URL + 'products?category=' + id;
      let productUrlRegex = productUrl.match(regex).join('');
      let cachedProductUrl = localStorage.getItem(productUrlRegex);
      if (cachedProductUrl) {
        return JSON.parse(cachedProductUrl);
      } else {
        try {
          const response = await axios.get(productUrl);
          localStorage.setItem(productUrlRegex, JSON.stringify(response.data.products));
          return response.data.products; // Fixed the return statement
        } catch (error) {
          console.error(error.message);
          throw error;
        }
      }
    },

    async loadCategory(id) {
      const regex = /[a-zA-Z0-9]/g;
      let categoryUrl = process.env.VUE_APP_SERVICE_URL + 'category?category=' + id;
      let categoryUrlRegex = categoryUrl.match(regex).join('');
      let cachedCategoryUrl = localStorage.getItem(categoryUrlRegex);
      if (cachedCategoryUrl) {
        return JSON.parse(cachedCategoryUrl);
      } else {
        try {
          const response = await axios.get(categoryUrl, {params: {category: id}});
          localStorage.setItem(categoryUrlRegex, JSON.stringify(response.data[0]));
          return response.data[0];
        } catch (error) {
          console.error(error.message);
          throw error;
        }
      }
    }
  },
};
</script>

<style>
</style>

