<template>
  <div class="navbar navbar-style-1">
    <div class="navbar-inner">
      <a href="/" @click="toggleMain" class="link back">
        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path
              d="M0.439312 13.0606L5.75391 18.3752C6.04683 18.6682 6.43069 18.8146 6.81459 18.8146C7.1985 18.8146 7.58236 18.6682 7.87528 18.3752C8.46103 17.7894 8.46103 16.8397 7.87528 16.2539L5.12133 13.5H22.5C23.3284 13.5 24 12.8284 24 12C24 11.1716 23.3284 10.5 22.5 10.5H5.12133L7.87528 7.7461C8.46103 7.1603 8.46103 6.21057 7.87528 5.62477C7.28944 5.03898 6.33975 5.03898 5.75391 5.62477L0.439312 10.9394C-0.146437 11.5251 -0.146437 12.4749 0.439312 13.0606Z"
              fill="black"/>
        </svg>
      </a>
      <div class="title">Finalizar pedido</div>
    </div>
  </div>
  <div class="page-content" style="padding: 0 0 0 0">
    <div class="sticky-top bg-white pb-20">
      <ul class="nav-wizard container" style="padding: 0px 0 15px">
        <li>
        </li>
        <li>
          <a class="nav-link active" href="/checkout-payment-method/">
            <span></span>
          </a>
        </li>
        <li>
        </li>
      </ul>
    </div>
    <div class="container">
      <div class="list accordion-list dz-list pb-10">
        <ul>
          <li class="accordion-item"
              :class="{'accordion-item': true, 'accordion-item-opened': this.mbWayAvailable === 1}">
            <a v-if="this.mbWayAvailable" href="#" class="item-link item-content">
              <div class="item-inner" style="display:flex; justify-content: flex-start;">
                <div class="item-title" style="font-weight: bold;">MbWay</div>
                <span v-if="!mbWayAvailable"
                      style="padding-left: 1%;color: red; font-size: 10px">(pagamento temporariamente indisponível)</span>
              </div>
            </a>
            <a v-else href="#" class="item item-content" style="color: #777777">
              <div class="item-inner" style="display:flex; justify-content: flex-start;">
                <div class="item-title" style="font-weight: bold;">MbWay</div>
                <span v-if="!mbWayAvailable"
                      style="padding-left: 1%;color: red; font-size: 10px">(pagamento temporariamente indisponível)</span>
              </div>
            </a>
            <div class="accordion-item-content px-5" v-if="showCheckoutOrder" :key="showCheckoutOrder">
              <div class="item-content-inner">
                <div class="list">
                  <ul class="row">
                    <li class="item-content item-input col-100">
                      <div class="item-inner">
                        <div style="margin-bottom: 0px" class="item-title item-label" id="mobile">Número de
                          telemóvel
                        </div>
                        <div class="item-input-wrap mt-5">
                          <input type="number" inputmode="numeric" maxlength="9" minlength="9" id="mobile"
                                 v-model="mobile" @input="validateMobile" pattern="9\d{8}"
                                 class="form-control"/>
                        </div>
                      </div>
                    </li>
                    <li class="item-content item-input col-100">
                      <div class="item-inner">
                        <div style="margin-bottom: 0px; font-size: 14px; font-weight: bold"
                             class="item-title item-label">Se
                          desejar a emissão da fatura com os seus dados, indique aqui:
                        </div>
                      </div>
                    </li>
                    <li class="item-content item-input col-100">
                      <div class="item-inner">
                        <div style="margin-bottom: 0px" class="item-title item-label invoiceFields" id="name">Nome</div>
                        <div class="item-input-wrap mt-5">
                          <input type="text" class="form-control" id="name" v-model="name" @input="validateName">
                        </div>
                      </div>
                    </li>
                    <li class="item-content item-input col-100 item-input-with-value">
                      <div class="item-inner">
                        <div style="margin-bottom: 0px" class="item-title item-label invoiceFields" id="vat">
                          Número de
                          identificação
                          fiscal
                        </div>
                        <div class="item-input-wrap mt-5">
                          <input type="number" inputmode="numeric" maxlength="9" minlength="9" id="vat" v-model="vat"
                                 @input="validateNif" pattern="9\d{8}"
                                 class="form-control"/>
                        </div>
                      </div>
                    </li>
                    <li class="item-content item-input col-100">
                      <div class="item-inner">
                        <div style="margin-bottom: 0px" class="item-title item-label invoiceFields" id="address">
                          Morada
                        </div>
                        <div class="item-input-wrap mt-5">
                          <input type="text" class="form-control" id="address" v-model="address"
                                 @input="validateAddress">
                        </div>
                      </div>
                    </li>
                    <li class="item-content item-input col-100">
                      <div class="item-inner">
                        <div style="margin-bottom: 0px" class="item-title item-label invoiceFields" id="locality">
                          Localidade
                        </div>
                        <div class="item-input-wrap mt-5">
                          <input type="text" class="form-control" id="locality" v-model="locality"
                                 @input="validateLocality">
                        </div>
                      </div>
                      <div class="item-inner">
                        <div style="margin-bottom: 0px" class="item-title item-label invoiceFields" id="postalCode">Cód.
                          Postal
                        </div>
                        <div class="item-input-wrap mt-5">
                          <input type="text" class="form-control" id="postalCode" v-model="postalCode"
                                 @input="validatePostalCode">
                        </div>
                      </div>
                    </li>
                    <li class="item-content item-input col-100 item-input-with-value">
                      <div class="item-inner">
                        <div style="margin-bottom: 0px" class="item-title item-label invoiceFields" id="email">E-mail
                        </div>
                        <div class="item-input-wrap mt-5">
                          <input type="email" id="email" name="email" class="form-control" v-model="email"
                                 @input="validateEmail">
                        </div>
                      </div>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </li>
        </ul>
      </div>
      <div class="checkout-total">
        <p class="title">Total a pagar</p>
        <h2 class="checkout-total">{{ totalPrice.toFixed(2).replace('.', ',') + ' €' }}</h2>
      </div>
    </div>
  </div>
  <div class="container" style="display: flex; justify-content: center;">
    <a href="/" class="button-large button rounded-lg btn-icon button-fill button-order" @click="togglePayment">Pagar
      <svg xmlns="http://www.w3.org/2000/svg" height="1em" viewBox="0 0 256 512" style="fill: #ffffff">
        <path
            d="M246.6 278.6c12.5-12.5 12.5-32.8 0-45.3l-128-128c-9.2-9.2-22.9-11.9-34.9-6.9s-19.8 16.6-19.8 29.6l0 256c0 12.9 7.8 24.6 19.8 29.6s25.7 2.2 34.9-6.9l128-128z"/>
      </svg>
    </a>
  </div>

</template>

<script>
import axios from "axios";
import {mapGetters} from "vuex";

export default {
  name: 'CheckoutOrder',
  props: ['totalPrice', 'showCheckoutOrder', 'hasCookies'],
  emits: ['toggle-cart', 'toggle-main', 'toggle-order-confirm'],

  data() {
    return {
      submitted: false,
      name: '',
      email: '',
      mobile: '',
      vat: '',
      address: '',
      locality: '',
      postalCode: '',
      amount: this.totalPrice,
      hasError: false,
      mbWayAvailable: 1,
      orderNumber: undefined,
    }
  },

  computed: {
    ...mapGetters(['getCartItems']),
    cartItems() {
      return this.getCartItems
    },
  },

  async mounted() {
    try {
      this.loadFromCookies();
      let payments = await this.loadAvailablePayments();
      payments.forEach((value) => {
        switch (value.description) {
          case 'mbway':
            this.mbWayAvailable = value.available;
            break;
        }
      });
    } catch (error) {
      console.error(error.message);
    }
  },

  methods: {
    async loadAvailablePayments() {
      let targetUrl = process.env.VUE_APP_SERVICE_URL + 'payment/methods';
      try {
        const response = await axios.get(targetUrl);
        return response.data;
      } catch (error) {
        console.error(error.message);
        throw error;
      }

    },
    validateNif() {
      const vat = this.vat;
      if (this.submitted) {
        if (this.validatePortugueseVAT(vat)) {
          const vatDivs = document.querySelectorAll('[id="vat"]');
          vatDivs.forEach((div) => {
            div.classList.remove('invalid');
          });
          this.NifhasError = false;
        } else {
          const vatDivs = document.querySelectorAll('[id="vat"]');
          vatDivs.forEach((div) => {
            div.classList.add('invalid');
          });
          this.NifhasError = true;
        }
      }
    },
    loadFromCookies() {
      if (this.hasCookies) {
        if (this.$cookies.get('_gc_name')) {
          this.name = decodeURIComponent(this.$cookies.get('_gc_name'));
        }
        if (this.$cookies.get('_gc_mobile')) {
          this.mobile = decodeURIComponent(this.$cookies.get('_gc_mobile'));
        }
        if (this.$cookies.get('_gc_vat')) {
          this.vat = decodeURIComponent(this.$cookies.get('_gc_vat'));
        }
        if (this.$cookies.get('_gc_email')) {
          this.email = decodeURIComponent(this.$cookies.get('_gc_email'));
        }
        if (this.$cookies.get('_gc_address')) {
          this.address = decodeURIComponent(this.$cookies.get('_gc_address'));
        }
        if (this.$cookies.get('_gc_postalcode')) {
          this.postalCode = decodeURIComponent(this.$cookies.get('_gc_postalcode'));
        }
        if (this.$cookies.get('_gc_locality')) {
          this.locality = decodeURIComponent(this.$cookies.get('_gc_locality'));
        }
      }
    },
    validateMobile() {
      const mobile = this.mobile
      if (!mobile || !/^9\d{8}$/.test(mobile) && this.submitted) {
        const mobileDivs = document.querySelectorAll('[id="mobile"]');
        mobileDivs.forEach(div => {
          div.classList.add('invalid');
        });
        this.MobilehasError = true;
      } else {
        const mobileDivs = document.querySelectorAll('[id="mobile"]');
        mobileDivs.forEach(div => {
          div.classList.remove('invalid');
        });
        this.MobilehasError = false;
      }
    },
    validateName() {
      const name = this.name.trim()
      if ((!name || name.length < 2 || name.length > 50) && this.submitted) {
        const nameDivs = document.querySelectorAll('[id="name"]');
        nameDivs.forEach(div => {
          div.classList.add('invalid');
        });
        this.NamehasError = true;
      } else {
        const nameDivs = document.querySelectorAll('[id="name"]');
        nameDivs.forEach(div => {
          div.classList.remove('invalid');
        });
        this.NamehasError = false;
      }
    },
    validateEmail() {
      const email = this.email;
      const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
      const isValidEmail = emailRegex.test(email);
      if (this.submitted) {
        if (!isValidEmail && this.submitted) {
          const emailDivs = document.querySelectorAll('[id="email"]');
          emailDivs.forEach(div => {
            div.classList.add('invalid');
          });
          this.EmailhasError = true;
        } else {
          const emailDivs = document.querySelectorAll('[id="email"]');
          emailDivs.forEach(div => {
            div.classList.remove('invalid');
          });
          this.EmailhasError = false;
        }
      } else {
        const emailDivs = document.querySelectorAll('[id="email"]');
        emailDivs.forEach(div => {
          div.classList.remove('invalid');
        });
        this.EmailhasError = false;
      }
    },
    validateAddress() {
      const address = this.address.trim();
      if ((!address || address.length < 2 || address.length > 50) && this.submitted) {
        const nameDivs = document.querySelectorAll('[id="address"]');
        nameDivs.forEach(div => {
          div.classList.add('invalid');
        });
        this.AddresshasError = true;
      } else {
        const nameDivs = document.querySelectorAll('[id="address"]');
        nameDivs.forEach(div => {
          div.classList.remove('invalid');
        });
        this.AddresshasError = false;
      }
    },
    validateLocality() {
      const locality = this.locality.trim();
      if ((!locality || locality.length < 2 || locality.length > 50) && this.submitted) {
        const nameDivs = document.querySelectorAll('[id="locality"]');
        nameDivs.forEach(div => {
          div.classList.add('invalid');
        });
        this.LocalityhasError = true;
      } else {
        const nameDivs = document.querySelectorAll('[id="locality"]');
        nameDivs.forEach(div => {
          div.classList.remove('invalid');
        });
        this.LocalityhasError = false;
      }
    },
    validatePostalCode() {
      const postalCode = this.postalCode
      if (!postalCode || !/^\d{4}-\d{3}$/.test(postalCode) && this.submitted) {
        const mobileDivs = document.querySelectorAll('[id="postalCode"]');
        mobileDivs.forEach(div => {
          div.classList.add('invalid');
        });
        this.postalCodehasError = true;
      } else {
        const mobileDivs = document.querySelectorAll('[id="postalCode"]');
        mobileDivs.forEach(div => {
          div.classList.remove('invalid');
        });
        this.postalCodehasError = false;
      }
    },
    hasInvoiceFields() {
      const vat = this.vat;
      const name = this.name.trim()
      const address = this.address.trim()
      const email = this.email;
      const locality = this.locality.trim();
      const postalCode = this.postalCode

      if (vat || name || email || address || locality || postalCode) {
        return true;
      } else {
        return false;
      }
    },
    validateInvoiceFields() {
      if (this.hasInvoiceFields) {
        if (this.vat !== '' && this.name !== '' && this.address !== '' && this.email !== '' && this.locality !== '' && this.postalCode !== '') {
          return true;
        } else {
          return false;
        }
      }
    },
    validatePortugueseVAT(value) {
      value = value + "";
      if (!/^[0-9]{9}$/.test(value)) return false;
      if (!/^[123]|45|5/.test(value)) return false;
      let tot =
          value[0] * 9 +
          value[1] * 8 +
          value[2] * 7 +
          value[3] * 6 +
          value[4] * 5 +
          value[5] * 4 +
          value[6] * 3 +
          value[7] * 2;
      let div = tot / 11;
      let mod = tot - parseInt(div) * 11;
      let tst = mod == 1 || mod == 0 ? 0 : 11 - mod;
      return value[8] == tst;
    },
    filterSkuAndQuantity(arr) {
      const filteredArray = arr.map((item) => {
        const {id, sku, quantity, products} = item;
        if (products) {
          const promo = {id, quantity};
          return {promo};
        } else {
          return {sku, quantity};
        }
      });
      return JSON.stringify(filteredArray);
    },
    cartToProducts(cartItems) {
      return this.filterSkuAndQuantity(cartItems);
    },
    toggleCart() {
      this.$emit('toggle-cart', true);
    },
    toggleMain() {
      this.$emit('toggle-main', true);
    },
    clearInvoiceFields() {
      const nameDivs = document.querySelectorAll('.invoiceFields');
      nameDivs.forEach(div => {
        div.classList.remove('invalid');
      });
      this.MobilehasError = false;
      this.NamehasError = false;
      this.NifhasError = false;
      this.AddresshasError = false;
      this.EmailhasError = false;
      this.LocalityhasError = false;
      this.postalCodehasError = false;
    },
    togglePayment() {
      this.submitted = true

      if (!this.hasInvoiceFields()) {
        this.validateMobile()
        this.clearInvoiceFields()
      } else {
        this.validateMobile();
        this.validateName();
        this.validateNif();
        this.validateAddress();
        this.validateEmail();
        this.validateLocality();
        this.validatePostalCode();
      }

      if (!this.MobilehasError && !this.NamehasError && !this.NifhasError && !this.AddresshasError && !this.EmailhasError && !this.LocalityhasError && !this.postalCodehasError) {
        if (this.mbWayAvailable) {
          let data;

          if (this.validateInvoiceFields()) {
            data = {
              name: this.name,
              mobile: this.mobile,
              vat: this.vat,
              email: this.email,
              address: this.address,
              postalCode: this.postalCode,
              locality: this.locality,
              amount: this.amount,
              products: this.cartToProducts(this.cartItems)
            };
          } else if (this.mobile !== '') {
            data = {
              mobile: this.mobile,
              amount: this.amount,
              products: this.cartToProducts(this.cartItems)
            }
          }

          let apiUrl = process.env.VUE_APP_SERVICE_URL + 'payment';
          apiUrl = apiUrl + '?' + this.generateQueryParams(data);

          const config = {
            headers: {
              'Content-Type': 'application/json'
            }
          };

          axios.get(apiUrl, config)
              .then(response => {
                if (response.status === 200) {
                  // Use cookies
                  if (this.hasCookies) {
                    if (this.name !== '') {
                      this.$cookies.set('_gc_name', encodeURIComponent(this.name), '30d');
                    }
                    if (this.mobile !== '') {
                      this.$cookies.set('_gc_mobile', encodeURIComponent(this.mobile), '30d');
                    }
                    if (this.vat !== '') {
                      this.$cookies.set('_gc_vat', encodeURIComponent(this.vat), '30d');
                    }
                    if (this.email !== '') {
                      this.$cookies.set('_gc_email', encodeURIComponent(this.email), '30d');
                    }
                    if (this.address !== '') {
                      this.$cookies.set('_gc_address', encodeURIComponent(this.address), '30d');
                    }
                    if (this.postalCode !== '') {
                      this.$cookies.set('_gc_postalcode', encodeURIComponent(this.postalCode), '30d');
                    }
                    if (this.locality !== '') {
                      this.$cookies.set('_gc_locality', encodeURIComponent(this.locality), '30d');
                    }
                  }

                  if (localStorage.getItem('waitingOrderNumber')) {
                    this.orderNumber = localStorage.getItem('waitingOrderNumber');
                  } else {
                    this.orderNumber = response.data;
                    localStorage.setItem('waitingOrderNumber', this.orderNumber);
                  }
                  this.$emit('toggle-order-confirm', this.orderNumber);
                }
              })
              .catch(error => {
                console.error(error.message);
              });
        }
      }
    },

    generateQueryParams(paramsObj) {
      const queryParams = [];
      for (const key in paramsObj) {
        if (Object.prototype.hasOwnProperty.call(paramsObj, key)) {
          const value = encodeURIComponent(paramsObj[key]);
          queryParams.push(`${key}=${value}`);
        }
      }
      return queryParams.join('&');
    }
  },
};

</script>

<style>
.invalid {
  color: #dc3545 !important;
  border-color: #dc3545 !important;
}
</style>

