import {createStore} from 'vuex'

function updateLocalStorage(cart) {
    localStorage.setItem('cart', JSON.stringify(cart))
}

export default createStore({
    state: {
        cart: []
    },
    getters: {
        getCartTotal(state) {
            return state.cart.reduce((total, item) => total + item.quantity, 0);
        },
        getItemQuantity: state => {
            return (product) => {
                const item = state.cart.find(i => i.id === product.id);
                if (item) {
                    return item.quantity;
                } else {
                    return 0;
                }
            };
        },
        getCartItems(state) {
            return state.cart.filter(item => item.quantity > 0);
        },
        getTotalPrice(state) {
            return state.cart.reduce((total, item) => {
                const itemTotal = item.price * item.quantity;
                return total + itemTotal;
            }, 0);
        }
    },
    mutations: {
        clearCart(state) {
            state.cart = [];
            updateLocalStorage(state.cart);
        },
        addToCard(state, product) {
            let item = state.cart.find(i => i.id === product.id)
            if (item) {
                item.quantity++
            } else {
                state.cart.push({...product, quantity: 1})
            }
            updateLocalStorage(state.cart)
        },

        removeFromCard(state, product) {
            let item = state.cart.find(i => i.id === product.id)
            if (item) {
                if (item.quantity > 1) {
                    item.quantity--
                } else {
                    state.cart = state.cart.filter(i => i.id !== product.id)
                }
            }
            updateLocalStorage(state.cart)
        },

        deleteFromCard(state, product) {
            let item = state.cart.findIndex(i => i.id === product.id);
            if (item !== -1) {
                this.state.cart.splice(item, 1);
            }
            updateLocalStorage(state.cart)
        },

        updateCartFromLocalStorage(state) {
            const cart = localStorage.getItem('cart')
            if (cart) {
                state.cart = JSON.parse(cart)
            }
        }
    },
    actions: {},
    modules: {}
})
