<template>
  <div class="container">
    <hr class="footer-hr">
    <div class="footer-side">
      <div class="row">
        <div class="footer-section">
          <p> GAUDEAMUS © 2023 - Direitos Reservados. </p>
        </div>
      </div>
      <div class="div" style="text-align: -webkit-center">
        <div class="footer row">
          <div class="col">
            <div class="footer-section" style="float: right">
              <div class="footer-social">
                <a href="#" id="instagramLink"><img src="/assets/img/social/instagram.png" style="height: 24px" alt=""/></a>
                <a href="#" id="twitterLink"><img src="/assets/img/social/twitter.png" style="height: 24px" alt=""/>
                </a>
                <a href="#" id="facebookLink"><img src="/assets/img/social/facebook.png" style="height: 24px"
                                                   alt=""/></a>
                <a href="#" id="youtubeLink"><img src="/assets/img/social/youtube.png" style="height: 24px" alt=""/></a>
              </div>
            </div>
          </div>
          <div class="col">
            <div style="text-align: left">
              <a style="color: #777777" class="footer-content" id="locationLink" href="">Localização</a><br>
              <a style="color: #777777;" class="footer-content" id="contactLink" href="">Contacte-nos</a><br>
              <a style="color: #777777" class="footer-content" id="ppdpLink" href="">Política de Privacidade</a>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

</template>

<script>

document.addEventListener('DOMContentLoaded', function () {
  var redirectLink = document.getElementById('youtubeLink');
  redirectLink.addEventListener('click', function (event) {
    event.preventDefault();
    window.open('https://www.youtube.com/channel/UCNWNvTG5nIcP-FkmR9hYq0Q', '_blank');
  });
});

document.addEventListener('DOMContentLoaded', function () {
  var redirectLink = document.getElementById('facebookLink');
  redirectLink.addEventListener('click', function (event) {
    event.preventDefault();
    window.open('https://twitter.com/aaumadeira', '_blank');
  });
});

document.addEventListener('DOMContentLoaded', function () {
  var redirectLink = document.getElementById('twitterLink');
  redirectLink.addEventListener('click', function (event) {
    event.preventDefault();
    window.open('https://twitter.com/aaumadeira', '_blank');
  });
});

document.addEventListener('DOMContentLoaded', function () {
  var redirectLink = document.getElementById('instagramLink');
  redirectLink.addEventListener('click', function (event) {
    event.preventDefault();
    window.open('https://www.instagram.com/aaumadeira/', '_blank');
  });
});

document.addEventListener('DOMContentLoaded', function () {
  var redirectLink = document.getElementById('ppdpLink');
  redirectLink.addEventListener('click', function (event) {
    event.preventDefault();
    window.open('https://amadeira.pt/ppdp/', '_blank');
  });
});

document.addEventListener('DOMContentLoaded', function () {
  var redirectLink = document.getElementById('contactLink');
  redirectLink.addEventListener('click', function (event) {
    event.preventDefault();
    window.open('https://gaudeamus.pt/pages/contacte-nos', '_blank');
  });
});

document.addEventListener('DOMContentLoaded', function () {
  var redirectLink = document.getElementById('locationLink');
  redirectLink.addEventListener('click', function (event) {
    event.preventDefault();
    window.open('https://maps.app.goo.gl/uqQdfFDwmW19WbTp6', '_blank');
  });
});

</script>

<style>
.footer {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  justify-content: space-between;
  bottom: 0;
}

.footer-hr {
  border-color: hsl(0deg 0% 97.65% / 19%);
}

.footer-section {
  flex: 1;
  margin: 0 10px;
  text-align: center;
}

.footer-social {
  display: flex;
  justify-content: flex-start;
  gap: 10px;
}

</style>
