<template>
    <div class="navbar navbar-style-1" style="top: unset">
      <div class="navbar-inner">
        <a href="/" @click="toggleMain" class="link back">
          <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                d="M0.439312 13.0606L5.75391 18.3752C6.04683 18.6682 6.43069 18.8146 6.81459 18.8146C7.1985 18.8146 7.58236 18.6682 7.87528 18.3752C8.46103 17.7894 8.46103 16.8397 7.87528 16.2539L5.12133 13.5H22.5C23.3284 13.5 24 12.8284 24 12C24 11.1716 23.3284 10.5 22.5 10.5H5.12133L7.87528 7.7461C8.46103 7.1603 8.46103 6.21057 7.87528 5.62477C7.28944 5.03898 6.33975 5.03898 5.75391 5.62477L0.439312 10.9394C-0.146437 11.5251 -0.146437 12.4749 0.439312 13.0606Z"
                fill="black"/>
          </svg>
        </a>
        <div class="title">Carrinho</div>
      </div>
    </div>

    <div class="page-content pt-5">

      <!-- Search backdrop -->
      <div class="searchbar-backdrop"></div>

      <div class="container">
        <!-- Search -->
        <form data-search-container=".search-list" data-search-in=".item-title"
              class="searchbar searchbar-init search-box list-search-bx">
          <div class="searchbar-inner">
            <div class="searchbar-input-wrap">
              <input type="search" placeholder="Procurar" v-model="search"/>
              <i class="searchbar-icon">
                <svg width="23" height="23" viewBox="0 0 23 23" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path
                      d="M22.707 21.293L17.6 16.182C19.1017 14.3173 19.8314 11.9479 19.6388 9.56147C19.4462 7.17505 18.3459 4.9533 16.5645 3.35365C14.7832 1.754 12.4563 0.898212 10.063 0.962481C7.66967 1.02675 5.39207 2.00618 3.69913 3.69913C2.00618 5.39207 1.02675 7.66967 0.962481 10.063C0.898212 12.4563 1.754 14.7832 3.35365 16.5645C4.9533 18.3459 7.17505 19.4462 9.56147 19.6388C11.9479 19.8314 14.3173 19.1017 16.182 17.6L21.293 22.711C21.4816 22.8931 21.7342 22.9939 21.9964 22.9917C22.2586 22.9894 22.5094 22.8842 22.6948 22.6988C22.8802 22.5134 22.9854 22.2626 22.9877 22.0004C22.9899 21.7382 22.8891 21.4856 22.707 21.297V21.293ZM2.99999 10.333C2.99999 8.88242 3.43015 7.46442 4.23606 6.25833C5.04197 5.05224 6.18745 4.11222 7.52762 3.55716C8.86779 3.0021 10.3425 2.85692 11.7651 3.13998C13.1878 3.42305 14.4946 4.12164 15.5203 5.14742C16.5459 6.1732 17.2443 7.48009 17.5272 8.90281C17.8101 10.3255 17.6647 11.8002 17.1094 13.1403C16.5542 14.4804 15.614 15.6257 14.4078 16.4315C13.2016 17.2372 11.7836 17.6672 10.333 17.667C8.38871 17.6646 6.52477 16.8911 5.15005 15.5162C3.77534 14.1413 3.00211 12.2773 2.99999 10.333Z"
                      fill="#BFBFBF"/>
                </svg>
              </i>
            </div>
          </div>
        </form>

        <div class="title-bar container">
          <h4 class="dz-title" style="margin-top: 1%">Produtos</h4>
        </div>
        <div class="container" style="text-align: center; height: 45vh" v-if="cartItems.length == 0">
          Carrinho vazio
        </div>
      </div>
      <div class="container cart-list-area">
        <div>
          <div class="tabs" v-if="cartItems.length > 0">
            <div id="tab-1" class="tab tab-active">
              <div class="list cart-list search-list searchbar-found item-list">
                <ul>
                  <li v-for="product in filteredProducts" :key="product.id" class="swipeout cart-item">
                    <div class="item-content swipeout-content">
                      <div class="item-inner">
                        <div class="item-info">
                          <div class="item-head">
                            <div style="display: flex">
                              <h6 class="item-title" style="color:black">{{ product.title }}</h6>
                              <div>
                                <img class="ml-5" style="width: 24px; height: 24px" :src="`${ allergen }`"
                                     v-for="allergen in product.alergenio_image"
                                     :key="allergen.id"/>
                              </div>
                            </div>
                          </div>
                          <div class="item-foot pt-10" style="text-align: -webkit-center;">
                            <ul style="display: flex; align-items: center;">
                              <li class="item-price">{{ (product.price.replace('.', ',') + ' €') }}</li>
                              <div>
                                <div class="stepper stepper-init stepper-round"
                                     style="border: 1px solid #c4c4c4; height: 42px">
                                  <div class="stepper-button-minus" style="border: 0"
                                       @click="removeFromCard(`${ product.id }`)"></div>
                                  <div class="stepper-input-wrap" style="border: 0"><input
                                      style="font-size: 18px;color: #000;" type="text"
                                      :value="itemQuantity(`${ product.id }`)" min="0" max="100"
                                      step="1"
                                      readonly/>
                                  </div>
                                  <div class="stepper-button-plus" @click="addToCart(`${ product.id }`)"
                                       style="border: 0"></div>
                                </div>
                              </div>
                              <li class="text-primary item-total">
                                {{ (product.quantity * product.price).toFixed(2).replace('.', ',') + ' €' }}
                              </li>
                            </ul>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="swipeout-actions-right">
                      <a href="" class="swipeout-delete">
                        <img src="../assets/img/order/lata-de-lixo.png" alt="" style="filter: invert(1)"
                             @click="deleteFromCard(`${ product.id }`)">
                      </a>
                    </div>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
</template>

<script>
import {mapGetters} from "vuex";

export default {
  name: 'ShopCart',
  props: ['prodProList', 'categoriesList'],
  emits: ['toggleMain'],

  data() {
    return {
      search: '',
      mainOpen: false,
    };
  },

  methods: {
    toggleMain() {
      this.$emit('toggle-main', true);
    },
    addToCart(id) {
      this.$store.commit('addToCard', this.cartItems.find(i => i.id === id))
    },
    removeFromCard(id) {
      this.$store.commit('removeFromCard', this.cartItems.find(i => i.id === id))
    },
    deleteFromCard(id) {
      this.$store.commit('deleteFromCard', this.cartItems.find(i => i.id === id))
    }
  },
  computed: {
    filteredProducts() {
      return this.cartItems.filter(product => (product.title.toLowerCase().includes(this.search.toLowerCase())));
    },
    ...mapGetters(['getCartItems']),
    cartItems() {
      return this.getCartItems
    },
    ...mapGetters(['getItemQuantity']),
    itemQuantity() {
      return (id) => this.getItemQuantity(this.cartItems.find(i => i.id === id));
    },
  },
};
</script>

<style>
</style>

