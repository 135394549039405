<template>
  <div class="title-bar">
    <h4 class="dz-title">Promoções</h4>
  </div>
  <div class="list search-list mt-0 mb-20 searchbar-found item-list">
    <ul class="row">
      <li v-for="promo in promoList" :key="promo.id"
          :class="['store-card', 'col-100', promoList.length > 2 ? 'medium-33' : 'medium-50', 'card-list', 'mb-20']">
        <div @click="addPromo(`${ promo.id }`)" class="item-bx item-list"
             style="padding: 2%; margin-bottom: 0px; cursor: pointer">
          <div class="dz-media" style="right: 30px; margin-right: 12%;">
            <a href="/"><img class="masterblur" style="width: 200%" :src="`${ promo.image }`" alt=""/></a>
          </div>
          <div class="dz-info" style="align-items: center">
            <div class="dz-head">
              <h6 class="item-title"><a href="/">{{ promo.name }}</a>
              </h6>
            </div>
            <div class="dz-meta">
              <ul>
                <li style="font-size: 14px" class="text-primary">{{ promo.description }}</li>
              </ul>
            </div>
            <div class="dz-meta mt-10">
              <ul>
                <li style="font-size: 14px" class="price">{{ promo.price }}€</li>
              </ul>
            </div>
          </div>
        </div>
      </li>
    </ul>
  </div>
</template>

<script>
import {mapGetters} from "vuex";

export default {
  name: 'PromoBar',
  props: ['promoList'],

  data() {
    return {
      cartUpdated: false,
      cartOpen: false,
    };
  },

  computed: {
    ...mapGetters(['getCartItems']),
    cartItems() {
      return this.getCartItems
    },
  },

  methods: {
    addPromo(id) {
      const findedItem = this.promoList.find((promo) => promo.id === id);
      const conflictingItemIndex = this.cartItems.findIndex((item) => {
        return (item.id === findedItem.id && item.image === findedItem.image && item.price === findedItem.price && item.sku === findedItem.sku && item.title === findedItem.title);
      });

      if (conflictingItemIndex > -1) {
        this.$store.commit('addToCard', findedItem)
      } else {
        const newItem = {...findedItem};
        newItem.id = `promo-${findedItem.id}`;
        this.$store.commit('addToCard', newItem)
      }
    },

    addToCart(id) {
      this.$store.commit('addToCard', this.category['products'].find(i => i.id === id))
    },
  },
};
</script>

<style>
.masterblur {
  filter: blur(0.5px) grayscale(35%);
  transition: filter 0.5s ease-in-out;
}

.masterblur:hover {
  filter: blur(0px) grayscale(30%);
}
</style>

