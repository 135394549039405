<template>
  <div class="container" v-if="mbWayAvailable" style="display: flex; justify-content: center;">
    <a href="/" @click="toggleCreateOrder"
       class="button-large button button-fill rounded-xl button-order big">Finalizar pedido<span
          style="opacity: 0.5;margin-left: 10px;">{{ totalPrice.toFixed(2).replace('.', ',') + ' €' }}</span></a>

  </div>

  <div class="container" v-else style="display: flex; justify-content: center;">
    <a href="/"
       class="button-large button button-fill rounded-xl button-order categories-bx small" style="color: red; background-color: white">Lamentamos, os pagamentos estão indisponíveis<span></span></a>
  </div>

</template>

<script>
import axios from "axios";

export default {
  name: 'CreateOrder',
  props: ['totalPrice'],

  data() {
    return {
      mbWayAvailable: 1,
    }
  },

  async mounted() {
    try {
      let payments = await this.loadAvailablePayments();
      payments.forEach((value) => {
        switch (value.description) {
          case 'mbway':
            this.mbWayAvailable = value.available;
            break;
        }
      });
    } catch (error) {
      console.error(error.message);
    }
  },

  methods: {
    toggleCreateOrder() {
      this.$emit('toggle-create-order', true);
    },

    async loadAvailablePayments() {
      let targetUrl = process.env.VUE_APP_SERVICE_URL + 'payment/methods';
      try {
        const response = await axios.get(targetUrl);
        return response.data;
      } catch (error) {
        console.error(error.message);
        throw error;
      }

    },
  }
};
</script>
