import {createRouter, createWebHistory} from 'vue-router'

import HomeView from "@/views/HomeView";

// To be added...
// let publicPath = process.env.VUE_APP_SERVICE_URL +VUE_APP_PLATFORM === 'LOCAL' ? '/dev/gaudeamus' : '/'

const routes = [
    {path: '/', name: 'home', component: HomeView},
    //{path: '/category/:id', name: 'category', component: CategoryView},
    //{path: '/products', name: 'products', component: ProductsView},
    //{path: '/product/:id', name: 'product', component: DetailsView},
    //{path: '/cart/', name: 'cart', component: ShoppingCart},
    //{path: '/checkout/', name: 'checkour', component: CheckoutOrder},
]

const router = createRouter({
    history: createWebHistory(),
    routes
})

export default router;
