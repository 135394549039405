<template>
  <div class="cc-window cc-banner cc-type-info cc-theme-block cc-bottom cc-color-override-699733349" id="cookie-div"
       style="">
    <span class="cc-message">Utilizamos “cookies” para melhorar a sua experiência e para analisar o tráfego do nosso portal. Por esses motivos, podemos compartilhar os dados que consentir com os nossos parceiros. Ao clicar em “Entendi”, concorda em armazenar no seu dispositivo todas as tecnologias descritas na nossa Política de Privacidade.
      <a role="button" tabindex="0" class="cc-link" id="cookiesLink" href="https://gaudeamus.pt/policies/privacy-policy"
         rel="noopener noreferrer nofollow" target="_blank">Nesse documento encontra todos os detalhes.</a>
    </span>
    <div class="cc-compliance"><a class="cc-btn cc-dismiss" id="ba-cookie-dismiss">Entendi</a></div>
  </div>
</template>

<script>
export default {
  name: 'CookieBar',
  props: ['totalPrice'],
  emits: ['toggle-cookie'],

  methods: {
    setCookies() {
      this.$cookies.set('_gc_cookies', 1, '30d');
      this.$emit('toggle-cookie', true);
    },
  },

  mounted() {
    const self = this;

    document.addEventListener('DOMContentLoaded', function () {
      var redirectLink = document.getElementById('cookiesLink');
      redirectLink.addEventListener('click', function (event) {
        event.preventDefault();
        window.open('https://gaudeamus.pt/policies/privacy-policy', '_blank');
      });
    });

    document.addEventListener('DOMContentLoaded', function () {
      var button = document.getElementById('ba-cookie-dismiss');
      if (button) {
        button.addEventListener('click', () => {
          self.setCookies();
        });
      }
    });
  },
};

</script>

<style>
.cc-link {
  color: rgb(30, 136, 229);
  text-decoration: underline;
}

.cc-color-override-699733349.cc-window {
  color: rgb(255, 255, 255);
  background-color: rgb(0, 0, 0);
}

.cc-banner.cc-bottom {
  left: 0;
  right: 0;
  bottom: 0;
}

.cc-window.cc-banner {
  -ms-flex-align: center;
  align-items: center;
}

.cc-window {
  font-family: Helvetica, Calibri, Arial, sans-serif;
  font-size: 16px;
  line-height: 1.5em;
  position: fixed;
  overflow: hidden;
  box-sizing: border-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: nowrap;
  flex-wrap: nowrap;
  z-index: 9999;
}

.cc-window.cc-banner {
  padding: 1em 1.8em;
  width: 100%;
  -ms-flex-direction: row;
  flex-direction: row;
}

.cc-btn {
  display: block;
  padding: 0.4em 0.8em;
  font-size: .9em;
  font-weight: 700;
  border-width: 2px;
  border-style: solid;
  text-align: center;
  white-space: nowrap;
  color: rgb(255, 255, 255);
  border-color: rgb(255, 255, 255);
}

.cc-banner .cc-btn:last-child {
  min-width: 140px;
}

</style>
