<template>
  <div class="container">
    <div class="navbar navbar-style-1" style="top: unset">
      <div class="navbar-inner">
        <div class="title">Confirmação do pedido</div>
      </div>
    </div>
    <div class="page-content pt-5">
      <div class="container">
        <div v-if="orderNumber" class="rewards-box bg-primary mb-40 mt-20" style="text-align: center">
          <h4 class="sub-title">Número de pedido
          </h4>
          <h1 class="title pt-60">{{ orderNumber }}</h1>
          <h6 class="sub-title pt-60">O teu pedido está a em preparação, assim que estiver pronto para recolha serás
            notificado.</h6>
          <div class="badge button button-fill rounded-xl" @click="verifyStatus">Verificar estado</div>
          <h5 style="color:burlywood; text-align: center" v-if="waitingMessage">{{ waitingMessage }}</h5>
          <h5 style="color:mintcream; text-align: center" v-if="successMessage">{{ successMessage }}</h5>
        </div>
        <div v-else class="rewards-box bg-primary mb-40 mt-20">
          <h2 class="sub-title">Obrigado! O teu pedido foi submetido com sucesso.</h2>
          <h6 class="sub-title">Por favor, verifica a notificação no teu telemóvel e aprova o pagamento para finalizares
            o teu pedido na GAUDEAMUS Café!</h6>
          <h6 class="sub-title">Após efetuares o pagamento, clica no botão "Verificar Pagamento" para obteres o número
            do
            teu pedido.</h6>
          <div class="badge button button-fill rounded-xl" @click="verifyOrder">Verificar pagamento</div>
          <div>
            <h5 style="color:darkred; text-align: center" v-if="errorMessage">{{ errorMessage }}</h5>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";

export default {
  name: 'OrderConfirm',
  props: ['paymentNumber'],

  data() {
    return {
      orderNumber: undefined,
      orderStatus: undefined,
      errorMessage: undefined,
      waitingMessage: undefined,
      successMessage: undefined,
      paymentOrder: this.paymentNumber
    };
  },

  mounted() {
    if (localStorage.getItem('waitingOrderNumber')) {
      this.paymentOrder = localStorage.getItem('waitingOrderNumber');
    }
  },

  methods: {
    verifyOrder() {
      if (this.paymentOrder) {
        let apiUrl = process.env.VUE_APP_SERVICE_URL + 'payment/check?id=' + this.paymentOrder;
        axios.get(apiUrl)
            .then(response => {
              if (response.status === 200) {
                this.orderNumber = response.data
                this.errorMessage = '';
              }
            })
            .catch(error => {
              console.error(error.message)
              this.errorMessage = 'O pagamento ainda não foi processado, verifica novamente.'
            });
      }
    },
    verifyStatus() {
      let apiUrl = process.env.VUE_APP_SERVICE_URL + 'order/status?id=' + this.paymentOrder;

      axios.get(apiUrl)
          .then(response => {
            if (response.status === 200) {
              this.orderStatus = response.data
              if (this.orderStatus) {
                this.successMessage = 'O pedido encontra-se pronto para recolha, dirige-te ao balcão.'
                this.waitingMessage = ''
              } else {
                this.waitingMessage = 'O pedido está em preparação.'
                this.successMessage = ''
              }
            }
          })
          .catch(error => {
            console.error(error.message)
          });
    },
    toggleCart() {
      this.$emit('toggle-cart', true);
    },
    toggleMain() {
      this.$emit('toggle-main', true);
    },
  },
};

</script>

<style>

@keyframes cart-total-animation {
  0% {
    transform: scale(1);
  }
  50% {
    transform: scale(1.2);
  }
  100% {
    transform: scale(1);
  }
}
</style>

